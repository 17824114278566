import React from 'react'

const Test = () => {
    return (
        <div>
            <label for="cars"> Customer</label>
            <select name="cars" id="cars">
                <option value="volvo">Volvo</option>
                <option value="saab">Saab</option>
                <option value="opel">Opel</option>
                <option value="audi">Audi</option>
            </select>
            <br></br>
        </div>
    )
}

export default Test
