import React, { useEffect, useState } from 'react'
import "../news/news.css"
import img from '../../assets/person-dummy.jpg'
import Pagination from '../../component/pagination/Pagination'
import { MdEdit } from 'react-icons/md'
import { MdDelete } from 'react-icons/md'
import { deleteCaller, getCaller } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { AiOutlineClose } from 'react-icons/ai'
import { IoSearchOutline } from 'react-icons/io5'
import { icon, toastData } from '../Toast'
const success = Swal.mixin(toastData.success)
const error = Swal.mixin(toastData.error)
const News = () => {

    const navigate = useNavigate()
    const [news, setNews] = useState([])
    const [pageLimit, setPageLimit] = useState(15);
    const [offset, setOffset] = useState(0);

    const [inputValue, setInputValue] = useState("")
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const newsData = async () => {

        const res = await getCaller(`admin/v1/get/all/news?limit=${pageLimit}&offset=${offset}`)
        setNews(res?.data)
    }

    const addDefaultImg = ev => {
        ev.target.src = "../../assets/user.png"
    }


    useEffect(() => {
        newsData()

    }, [])


    const deleteNews = async (item) => {
        toastData.confirm.text = "You won't to Delete News"
        Swal.fire(toastData.confirm).then(async (result) => {
            if (result.isConfirmed) {
                const res1 = await deleteCaller(`admin/v1/delete/news?news_id=${item}`)
                if (res1?.status === 'success') {

                }
                icon.confirm.text = "News Deleted Successfully"
                Swal.fire(icon.confirm)
                newsData()
            }
        })

    }


    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(news?.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = news?.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reset to the first page when changing items per page
    };
    const currentNews = currentData?.filter((el) => el?.heading?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1);


    return (
        <>

            <div className="main-table-header">
                <div className="main-table-content">
                    <div className="wrapper-head">
                        <h3>News List</h3>
                        <div className='search-news-section'>
                            <div className="head-btn">
                                <button onClick={(() => navigate('/news/AddNews'))}>Add News</button>
                            </div>
                            <div className='searchcard' >
                                <input type="text" name="" id="" placeholder='Search' className='result-input'
                                    onChange={event => { setInputValue(event.target.value); }}
                                    value={inputValue}
                                />
                                {inputValue ? <div className="ai-close">
                                    <AiOutlineClose onClick={(event) => setInputValue('')}
                                    />
                                </div> : <div className='ai-close'> <IoSearchOutline /></div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="table-header">
                    <table style={{overflowX:"auto",whiteSpace:"nowrap"}}>
                        <thead>
                            <tr className='custom-row'>
                                <th width={50}> Id </th>
                                <th width={330}> Title </th>
                                <th width={400}> Content </th>
                                <th width={200}> Cover Image</th>
                                <th width={150}> Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentNews?.length > 0 ? currentNews?.map((el, i) => (
                                    <tr className='custom-row' key={i} >
                                        <td width={50} data-label="UID" style={{ width: "4rem" }}> <p className='uid_name' >{el?.news_id} </p> </td>
                                        <td width={380} data-label="SPORT"> {el?.heading?.substring(0, 30) + "..."}</td>
                                        <td width={500} data-label="EVENT NAME"> {el?.content?.substring(0, 40) + "..."}</td>
                                        <td data-label="MARKET" width={200}>
                                            <div className="news-image">
                                                {
                                                    el?.cover_image ? <img src={el?.cover_image} alt='' /> : <img src={img} alt='' style={{border:"1px solid gray", borderRadius:"50%"}} />
                                                }
                                              
                                            </div>
                                        </td>
                                        <td data-label="STATUS">
                                            <div className='user-icon' width={100}>
                                                <div className="icon-action" onClick={(() => navigate('/news/AddNews', {
                                                    state: {
                                                        singleNews: el
                                                    }
                                                }))} >
                                                    <MdEdit className='mdDelete' />
                                                    <span className="tooltiptext">Update News</span>

                                                </div>
                                                <div className="icon-action" style={{ backgroundColor: "#F44464" }} onClick={() => deleteNews(el.news_id)}>
                                                    <MdDelete className='mdDelete' />
                                                    <span className="tooltiptext">Delete News</span>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="pagination-container">
                    {
                        news?.length > 0 ? <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                            itemsPerPage={itemsPerPage}
                        /> : null
                    }
                </div>
            </div>
        </>
    )
}
export default News