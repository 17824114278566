import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { toastData } from '../Toast'
import { icon } from '../Toast'
import * as Yup from 'yup';
import { postCaller, updateCaller } from '../../services/api.js'
import { AiOutlineClose } from 'react-icons/ai'
import Swal from 'sweetalert2';
const success = Swal.mixin(toastData.success)
const error = Swal.mixin(toastData.error)


const NewsAdd = () => {
  const navigate = useNavigate()
  const { state } = useLocation();

  const getSingleNews = state?.singleNews ?? {}
  console.log(getSingleNews)
  const isAddMode = !getSingleNews.news_id
console.log(isAddMode)

  useEffect(() => {
    const getUpdatedData = async () => {
      const res = await getSingleNews
      formik.setValues(values => ({
        ...values,
        news_id: res.news_id,
        heading: res.heading,
        sub_heading: res.sub_heading,
        content: res.content,
        cover_image: res.cover_image,
      }))
    }
    getUpdatedData()
  }, [])

  const formik = useFormik({
    initialValues: {
      heading: "",
      sub_heading: "",
      content: "",
      docs: null,
    },
    // validationSchema: validationLogin,
    onSubmit: async (values) => {
      console.log(values)
      const formData = new FormData();
      formData.append("heading", values.heading)
      formData.append("sub_heading", values.sub_heading)
      formData.append("content", values.content)
      formData.append("docs", values.docs, values.docs.name)
      if(isAddMode===false){
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/v1/updateNews/${getSingleNews?.news_id}`,
      {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      }).then(response => response.json()).catch(error => console.log(error))
        if (response.status === true) {
          success.fire(Object.assign(icon.success, { title: response.msg })).then((response)=>(
            navigate('/news')
          ))
      }
    }
    else{
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/v1/insert/news`,
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      }).then(response => response.json()).catch(error => console.log(error))
      if (response.status === true) {
        success.fire(Object.assign(icon.success, { title: response.msg })).then((response)=>(
          navigate('/news')
        ))
       
    }
    
    }
}})

  return (

    < div className="main-container">
      <div className="form-content">
        <div>
          <div className='form-heading'>
            <p>{isAddMode ? "Add News" : "Update News"}</p>
            <AiOutlineClose className='ai-close' onClick={() => navigate("/news")} />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='form-input-head'>
              <div className="form-input-field">
                <label htmlFor="date"> Heading <span style={{color: 'red'}}>*</span> </label> <br />
                <input type="text" name="heading" id="heading" placeholder='Heading'
                  onChange={formik.handleChange}
                  value={formik.values.heading}
                  className='input-field'
                // onBlur={formik.handleBlur} 

                />

                {/* { formik.errors.password && formik.touched.password &&  <span className="error" style={{ color: "red" }}>
            {formik.errors.password}
          </span>} */}
              </div>
              <div className="form-input-field">
                <label htmlFor="date"> Sub Heading <span style={{
                  color: 'red'
                }}>*</span> </label> <br />
                <textarea type="text" name='sub_heading' placeholder='Sub_heading'
                  onChange={formik.handleChange}
                  value={formik.values.sub_heading}
                  //  onBlur={formik.handleBlur}
                  className='input-field' />
                {/* { formik.errors.password && formik.touched.password &&  <span className="error" style={{ color: "red" }}>
            {formik.errors.password}
          </span>} */}
              </div>
             

              <div className="form-input-field">
                <label htmlFor=""> Cover Image <span style={{ color: 'red' }}>*</span> </label> <br />
                <input type="file"
                  id="docs"
                  name='docs'
                  accept='.jpg, .jpeg, .png'
                  onChange={(e) => {
                    formik.setFieldValue('docs', e.currentTarget.files[0]);
                    console.log(e.currentTarget.files[0])
                  }}
                  className='input-field' />
                {/* { formik.errors.password && formik.touched.password &&  <span className="error" style={{ color: "red" }}>
            {formik.errors.password}
          </span>} */}
              </div>
              <div className="form-input-field">
                <label htmlFor="date"> Content <span style={{
                  color: 'red'
                }}>*</span> </label> <br />
                <textarea type="text" name='content' placeholder='Content'
                  //  onBlur={formik.handleBlur}
                  value={formik.values.content}
                  onChange={formik.handleChange}
                  className='input-field' />
                {/* { formik.errors.password && formik.touched.password &&  <span className="error" style={{ color: "red" }}>
            {formik.errors.password}
          </span>} */}
              </div>





            </div>
            <div className='submit-btn'>
              <button type='submit' >Submit</button>
            </div>

            {/* { formik.errors.managerPassword && formik.touched.managerPassword &&  <span className="error" style={{ color: "red",marginLeft:"1rem" }}>
              {formik.errors.managerPassword}
            </span>}
           */}
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewsAdd